import { useState } from "react";
import { toast } from "react-hot-toast";
import { FaCheckCircle, FaRegCopy } from "react-icons/fa";
import { JSONTree } from "react-json-tree";

import cn from "../../util/cn.js";
import Tooltip from "../Tooltip/Tooltip.js";

interface Props {
  json: any;
  className?: string;
  condensed?: boolean;
  interactive?: boolean;
  defaultExpandedLevels?: number;
}

const JsonViewer = ({
  className,
  json,
  condensed,
  interactive,
  defaultExpandedLevels = 2,
}: Props) => {
  const [copied, setCopied] = useState(false);
  const handleClick = () => {
    setCopied(true);
    navigator.clipboard
      .writeText(JSON.stringify(json))
      .then(() => toast.success("Copied to clipboard"))
      .catch((e) => toast.error(`Failed to copy to clipboard: ${e}`))
      .finally(() =>
        setTimeout(() => {
          setCopied(false);
        }, 3000)
      );
  };

  if (condensed) {
    return (
      <Tooltip tooltip={JSON.stringify(json, null, 2)}>
        <table className="font-mono">
          <tbody>
            {Object.keys(json).map((key) => {
              let value = json[key];

              if (typeof value === "object" && value !== null) {
                value = JSON.stringify(value);
              } else if (typeof value === "boolean") {
                value = value.toString();
              }

              return (
                <tr key={key}>
                  <td>
                    <div className="whitespace-nowrap px-1 text-xs text-secondary">{key}:</div>
                  </td>
                  <td>
                    <div className="line-clamp-1 overflow-ellipsis px-1 text-xs">{value}</div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Tooltip>
    );
  }

  return (
    <div className="group relative overflow-x-auto">
      {interactive && (
        <JSONTree
          hideRoot
          data={json}
          theme={{}}
          invertTheme
          shouldExpandNodeInitially={(keyPath, data, level) => {
            return level < defaultExpandedLevels;
          }}
        />
      )}
      {!interactive && (
        <pre
          className={cn(
            "whitespace-pre-wrap break-all rounded-md font-mono text-xs text-secondary",
            {
              "border border-dashed bg-surface-muted p-4": !condensed,
            },
            className
          )}
        >
          {JSON.stringify(json, null, condensed ? 1 : 4)}
        </pre>
      )}
      <button
        type="button"
        onClick={handleClick}
        className="text-gray-500 absolute right-2 top-2  rounded p-3 opacity-0 transition-colors  hover:bg-accent/10 hover:text-accent group-hover:opacity-100"
      >
        {copied && <FaCheckCircle />}
        {!copied && <FaRegCopy />}
      </button>
    </div>
  );
};

export default JsonViewer;
